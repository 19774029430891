// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chosen-options {
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom: 1px solid #d9d9d9;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/customization/coupon-editor/ChosenOption.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,mBAAA;EACA,gCAAA;AADF","sourcesContent":["@import '../../../sass/variables.scss';\n\n.chosen-options {\n  padding-bottom: 8px;\n  margin-bottom: 12px;\n  border-bottom: 1px solid $grayGray5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
