// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-intro-list {
  display: flex;
  gap: 16px;
  max-width: 726px;
  margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/onboarding/IntroList.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".onboarding-intro-list {\n  display: flex;\n  gap: 16px;\n  max-width: 726px;\n  margin-left: auto;\n  margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
