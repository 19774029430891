// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WidgetEmptyStep_base__2zxvC {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
.WidgetEmptyStep_base__2zxvC .WidgetEmptyStep_illustration__GqlJT {
  padding: 30px 20px;
}`, "",{"version":3,"sources":["webpack://./src/campaign/WidgetEmptyStep.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;AACJ","sourcesContent":[".base {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  text-align: center;\n\n  .illustration {\n    padding: 30px 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `WidgetEmptyStep_base__2zxvC`,
	"illustration": `WidgetEmptyStep_illustration__GqlJT`
};
export default ___CSS_LOADER_EXPORT___;
