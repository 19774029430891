// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.complete-setup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.complete-setup__row {
  display: flex;
  gap: 32px;
}
.complete-setup__tasks {
  width: 44%;
  position: relative;
}
.complete-setup__state {
  width: calc(56% - 32px);
}

.complete-tasks {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.complete-data {
  display: flex;
  gap: 29px;
  height: 100%;
}
.complete-data__content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}
.complete-data__image {
  position: relative;
  margin-top: -27px;
}
.complete-data__image-wrapper {
  width: calc(50% - 29px);
}
.complete-data__buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.complete-data__button-wrapper {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
}`, "",{"version":3,"sources":["webpack://./src/dashboard/CompleteSetup.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADF;AAEE;EACE,aAAA;EACA,SAAA;AAAJ;AAEE;EACE,UAAA;EACA,kBAAA;AAAJ;AAEE;EACE,uBAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AADF;;AAIA;EACE,aAAA;EACA,SAAA;EACA,YAAA;AADF;AAEE;EACE,UAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,QAAA;AAAJ;AAEE;EACE,kBAAA;EACA,iBAAA;AAAJ;AAEE;EACE,uBAAA;AAAJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAAJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,6BAAA;AAAJ","sourcesContent":["@import '../sass/variables.scss';\n\n.complete-setup {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  &__row {\n    display: flex;\n    gap: 32px;\n  }\n  &__tasks {\n    width: 44%;\n    position: relative;\n  }\n  &__state {\n    width: calc(56% - 32px);\n  }\n}\n\n.complete-tasks {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.complete-data {\n  display: flex;\n  gap: 29px;\n  height: 100%;\n  &__content {\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    gap: 8px;\n  }\n  &__image {\n    position: relative;\n    margin-top: -27px;\n  }\n  &__image-wrapper {\n    width: calc(50% - 29px);\n  }\n  &__buttons {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n  &__button-wrapper {\n    position: absolute;\n    right: 16px;\n    top: 50%;\n    transform: translate(0, -50%);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
