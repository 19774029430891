// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-sellers-table th:nth-child(2), .top-sellers-table th:nth-child(3), .top-sellers-table th:nth-child(4),
.top-sellers-table td:nth-child(2),
.top-sellers-table td:nth-child(3),
.top-sellers-table td:nth-child(4) {
  text-align: right;
}
.top-sellers-table th:nth-child(2) > div > div, .top-sellers-table th:nth-child(3) > div > div, .top-sellers-table th:nth-child(4) > div > div,
.top-sellers-table td:nth-child(2) > div > div,
.top-sellers-table td:nth-child(3) > div > div,
.top-sellers-table td:nth-child(4) > div > div {
  justify-content: flex-end;
}
.top-sellers-table tbody {
  max-height: 252px;
  overflow: auto;
}
.top-sellers-table thead {
  position: sticky;
  top: 0;
}
.top-sellers-table table {
  max-height: 292px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/performance/TopSellers.scss"],"names":[],"mappings":"AAGI;;;;EAGE,iBAAA;AADN;AAGQ;;;;EACE,yBAAA;AAEV;AAGE;EACE,iBAAA;EACA,cAAA;AADJ;AAGE;EACE,gBAAA;EACA,MAAA;AADJ;AAGE;EACE,iBAAA;EACA,cAAA;AADJ","sourcesContent":[".top-sellers-table {\n  th,\n  td {\n    &:nth-child(2),\n    &:nth-child(3),\n    &:nth-child(4) {\n      text-align: right;\n      > div {\n        > div {\n          justify-content: flex-end;\n        }\n      }\n    }\n  }\n  tbody {\n    max-height: 252px;\n    overflow: auto;\n  }\n  thead {\n    position: sticky;\n    top: 0;\n  }\n  table {\n    max-height: 292px;\n    overflow: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
