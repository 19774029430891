// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepWizardNavigation_base__t1UB5 {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  padding: 0 24px;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/step-wizard/StepWizardNavigation.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,SAAA;AACJ","sourcesContent":[".base {\n    display: flex;\n    justify-content: space-between;\n    position: absolute;\n    width: 100%;\n    padding: 0 24px;\n    bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `StepWizardNavigation_base__t1UB5`
};
export default ___CSS_LOADER_EXPORT___;
