// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-plugin {
  max-width: 624px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  counter-reset: download-plugin;
  gap: 16px;
}
.download-plugin__step {
  flex-grow: 1;
  flex-basis: 0;
  counter-increment: download-plugin;
  padding: 24px 16px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.download-plugin__title:before {
  content: counter(download-plugin) ". ";
}`, "",{"version":3,"sources":["webpack://./src/onboarding/DownloadPlugin.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;AADF;AAEE;EACE,YAAA;EACA,aAAA;EACA,kCAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;AAAJ;AAGI;EACE,sCAAA;AADN","sourcesContent":["@import '../sass/variables.scss';\n\n.download-plugin {\n  max-width: 624px;\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n  counter-reset: download-plugin;\n  gap: 16px;\n  &__step {\n    flex-grow: 1;\n    flex-basis: 0;\n    counter-increment: download-plugin;\n    padding: 24px 16px;\n    border-radius: 4px;\n    border: 1px solid $grayGray5;\n  }\n  &__title {\n    &:before {\n      content: counter(download-plugin) '. ';\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
