// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RatingEditor_rating-container__uyvb2 {
  display: flex;
  gap: 4px;
}
.RatingEditor_rating-container__uyvb2 div {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/customization/rating-editor/RatingEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;AACF;AAAE;EACE,eAAA;AAEJ","sourcesContent":[".rating-container {\n  display: flex;\n  gap: 4px;\n  div {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rating-container": `RatingEditor_rating-container__uyvb2`
};
export default ___CSS_LOADER_EXPORT___;
