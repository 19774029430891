// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinksEditor_add-wrapper__BEPdN {
  padding-bottom: 16px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/customization/links-editor/LinksEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,eAAA;AACF","sourcesContent":[".add-wrapper {\n  padding-bottom: 16px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add-wrapper": `LinksEditor_add-wrapper__BEPdN`
};
export default ___CSS_LOADER_EXPORT___;
