// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-button {
  min-height: 80px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.widget-button__emoji {
  font-size: 24px;
  line-height: 1.5;
  padding-left: 8px;
  padding-right: 8px;
}
.widget-button__body {
  width: calc(100% - 40px);
}`, "",{"version":3,"sources":["webpack://./src/widgets/WidgetButton.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,wBAAA;AAEJ","sourcesContent":[".widget-button {\n  min-height: 80px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  cursor: pointer;\n  &__emoji {\n    font-size: 24px;\n    line-height: 1.5;\n    padding-left: 8px;\n    padding-right: 8px;\n  }\n  &__body {\n    width: calc(100% - 40px);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
