// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-h2-lg {
  font-weight: 700;
  font-size: 45px;
  line-height: 1.16;
  margin: 0;
}

.ui-h3-lg {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.22;
  margin: 0;
}

.ui-other-headings-h5-bold {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0;
}

.ui-email-templates-h1-semibold {
  margin: 0;
  font-size: 34px;
  line-height: 1.18;
  font-weight: 600;
}

.ui-email-templates-h1-bold {
  margin: 0;
  font-size: 34px;
  line-height: 1.18;
  font-weight: 700;
}

.ui-other-headings-h4-bold {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.0025em;
  margin: 0;
}

.ui-other-headings-h5-semibold {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}

.ui-email-templates-subtitle-1-semibold {
  margin: 0;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;
}

.ui-email-templates-subtitle-2-bold {
  margin: 0;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
}

@media only screen and (max-width: 991px) {
  .ui-h3-lg-mobile {
    font-size: 36px;
    line-height: 1.22;
    font-weight: 600;
  }
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/text/UiHeading.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,SAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,SAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,SAAA;AADF;;AAIA;EACE,SAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AADF;;AAIA;EACE,SAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,SAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,SAAA;AADF;;AAIA;EACE,SAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,SAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE;IACE,eAAA;IACA,iBAAA;IACA,gBAAA;EADF;AACF","sourcesContent":["@import '../../sass/variables.scss';\n\n.ui-h2-lg {\n  font-weight: 700;\n  font-size: 45px;\n  line-height: 1.16;\n  margin: 0;\n}\n\n.ui-h3-lg {\n  font-weight: 700;\n  font-size: 36px;\n  line-height: 1.22;\n  margin: 0;\n}\n\n.ui-other-headings-h5-bold {\n  font-size: 24px;\n  line-height: 1.5;\n  font-weight: 600;\n  margin: 0;\n}\n\n.ui-email-templates-h1-semibold {\n  margin: 0;\n  font-size: 34px;\n  line-height: 1.18;\n  font-weight: 600;\n}\n\n.ui-email-templates-h1-bold {\n  margin: 0;\n  font-size: 34px;\n  line-height: 1.18;\n  font-weight: 700;\n}\n\n.ui-other-headings-h4-bold {\n  font-size: 30px;\n  font-weight: 600;\n  line-height: 1.33;\n  letter-spacing: 0.0025em;\n  margin: 0;\n}\n\n.ui-other-headings-h5-semibold {\n  font-size: 24px;\n  font-weight: 600;\n  line-height: 1.5;\n  margin: 0;\n}\n\n.ui-email-templates-subtitle-1-semibold {\n  margin: 0;\n  font-size: 20px;\n  line-height: 1.2;\n  font-weight: 700;\n}\n\n.ui-email-templates-subtitle-2-bold {\n  margin: 0;\n  font-size: 24px;\n  line-height: 1.5;\n  font-weight: 700;\n}\n\n@media only screen and (max-width: $mobileWidth) {\n  .ui-h3-lg-mobile {\n    font-size: 36px;\n    line-height: 1.22;\n    font-weight: 600;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
