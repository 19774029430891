import moment from 'moment';
import UiSmallRegular from "../ui-elements/text/UiSmallRegular";
import "./NotificationItem.scss";

const NotificationItem = props => {
  return (
    <li className={`notification-item${props.notification.unread ? ' notification-item_unread' : ''}`}>
      {props.notification.avatar ? (
        <div className="notification-item__avatar">
          <img
            src={props.notification.avatar}
            alt=""
            className="notification-item__avatar-image"
          />
        </div>
      ) : ''}
      <div className="notification-item__body">
        <UiSmallRegular text={props.notification.message} />
        <UiSmallRegular
          margin="4"
          text={moment.utc(props.notification.date).format('MMM DD, YYYY [at] h:mm a')}
          className="color-secondary-colour-secondary-4"
        />
      </div>
    </li>
  )
}

export default NotificationItem;