// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomizationContainer_base__QKBse {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px 32px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.CustomizationContainer_base__QKBse .CustomizationContainer_inner__vjEDQ {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 100%;
  overflow: auto;
}
.CustomizationContainer_base__QKBse .CustomizationContainer_inner_widgets__FWb0k {
  height: calc(100% - 84px);
}
.CustomizationContainer_base__QKBse .CustomizationContainer_customizer-preview__STh6t {
  background: #f6f6f6;
  width: 100%;
  height: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  transition: width ease 0.3s;
}
.CustomizationContainer_base__QKBse .CustomizationContainer_customizer-preview__STh6t.CustomizationContainer_no-border__pZ1bP {
  border: none !important;
}
.CustomizationContainer_base__QKBse .CustomizationContainer_customizer-preview__STh6t.CustomizationContainer_mobile__AbwE3 {
  width: 360px;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/customization/customization-container/CustomizationContainer.module.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AADF;AAGE;EACE,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAKE;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,2BAAA;AAHJ;AAKI;EACE,uBAAA;AAHN;AAMI;EACE,YAAA;AAJN","sourcesContent":["@import \"../../../sass/variables.scss\";\n\n.base {\n  border: 1px solid $grayGray5;\n  border-radius: 8px;\n  padding: 16px 32px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n\n  .inner {\n    border: 1px solid $grayGray5;\n    border-radius: 4px;\n    height: 100%;\n    overflow: auto;\n\n    &_widgets {\n      height: calc(100% - 84px);\n    }\n  }\n\n  .customizer-preview {\n    background: #f6f6f6;\n    width: 100%;\n    height: 100%;\n    border: 1px solid $grayGray5;\n    border-radius: 4px;\n    margin-left: auto;\n    margin-right: auto;\n    transition: width ease 0.3s;\n    \n    &.no-border {\n      border: none !important;\n    }\n\n    &.mobile {\n      width: 360px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `CustomizationContainer_base__QKBse`,
	"inner": `CustomizationContainer_inner__vjEDQ`,
	"inner_widgets": `CustomizationContainer_inner_widgets__FWb0k`,
	"customizer-preview": `CustomizationContainer_customizer-preview__STh6t`,
	"no-border": `CustomizationContainer_no-border__pZ1bP`,
	"mobile": `CustomizationContainer_mobile__AbwE3`
};
export default ___CSS_LOADER_EXPORT___;
