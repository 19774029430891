// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sync-in-progress {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.sync-in-progress__preloader {
  position: relative;
  width: 64px;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
}
.sync-in-progress__preloader .preloader {
  position: absolute;
  transform: none;
  left: 0;
  top: 0;
}
.sync-in-progress__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.sync-in-progress__button {
  min-width: 192px;
}
.sync-in-progress__social {
  width: 40px;
  height: 40px;
  display: block;
  text-decoration: none;
}
.sync-in-progress__social-icon {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/onboarding/SyncInProgress.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AADI;EACE,kBAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;AAGN;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;AAEJ;AAAE;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,SAAA;AAEJ","sourcesContent":[".sync-in-progress {\n  max-width: 900px;\n  margin-left: auto;\n  margin-right: auto;\n  &__preloader {\n    position: relative;\n    width: 64px;\n    height: 64px;\n    margin-left: auto;\n    margin-right: auto;\n    .preloader {\n      position: absolute;\n      transform: none;\n      left: 0;\n      top: 0;\n    }\n  }\n  &__buttons {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 16px;\n  }\n  &__button {\n    min-width: 192px;\n  }\n  &__social {\n    width: 40px;\n    height: 40px;\n    display: block;\n    text-decoration: none;\n  }\n  &__social-icon {\n    width: 100%;\n    height: 100%;\n    display: block;\n    margin: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
