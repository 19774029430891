// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerificationStatus_verification-status__Rqp\\+g {
  display: flex;
  gap: 8px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/verification-status/VerificationStatus.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACF","sourcesContent":[".verification-status {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verification-status": `VerificationStatus_verification-status__Rqp+g`
};
export default ___CSS_LOADER_EXPORT___;
