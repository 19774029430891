import { useState } from "react";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import NotificationItem from "./NotificationItem";
import NotificationsEmpty from "./NotificationsEmpty";
import "./NotificationsDropdown.scss";

const NotificationsDropdown = props => {
  const [activeTab, setActiveTab] = useState('unread');
  
  const unreadMessages = [
    {
      avatar: '/demo/images/Avatar.png',
      message: 'You received an email from Customer name.',
      date: '2023-04-13T18:15:27.324+00:00',
      unread: true
    },
    {
      avatar: '/demo/images/Avatar (1).png',
      message: 'You received an email from Customer name.',
      date: '2023-04-13T18:15:27.324+00:00',
      unread: true
    },
    {
      avatar: '/demo/images/Avatar (2).png',
      message: 'You received an email from Customer name.',
      date: '2023-04-13T18:15:27.324+00:00',
      unread: true
    },
    {
      avatar: '/demo/images/Avatar (3).png',
      message: 'You received an email from Customer name.',
      date: '2023-04-13T18:15:27.324+00:00',
      unread: true
    }
  ];

  const readMessages = [
    {
      avatar: '/demo/images/Avatar (2) copy.png',
      message: 'You received an email from Customer name.',
      date: '2023-04-13T18:15:27.324+00:00',
      unread: false
    },
    {
      avatar: '/demo/images/Avatar (3) copy.png',
      message: 'You received an email from Customer name.',
      date: '2023-04-13T18:15:27.324+00:00',
      unread: false
    },
    {
      avatar: '/demo/images/Avatar (4).png',
      message: 'You received an email from Customer name.',
      date: '2023-04-13T18:15:27.324+00:00',
      unread: false
    },
    {
      avatar: '/demo/images/Avatar (5).png',
      message: 'You received an email from Customer name.',
      date: '2023-04-13T18:15:27.324+00:00',
      unread: false
    },
  ];

  const [notifications, setNotifications] = useState(unreadMessages);

  const loadNotifications = type => {
    setActiveTab(type);
    
    if (type === 'unread') {
      setNotifications(unreadMessages);
    } else if (type === 'read') {
      setNotifications(readMessages);
    }
  }

  const markAllAsRead = () => {
    props.markAllAsRead();

    setNotifications([]);
  }

  return (
    <div className="notifications-dropdown">
      <div className="notifications-dropdown__head">
        <UiCaptionMedium text="Notifications" />
        <button className="notifications-dropdown__read ui-small-regular" onClick={markAllAsRead}>Mark all as read</button>
      </div>
      <div className="notifications-dropdown__nav mt-16">
        <button className={`notifications-nav-button ui-small-medium${activeTab === 'unread' ? ' notifications-nav-button_active' : ''}`} onClick={() => loadNotifications('unread')}>Unread</button>
        <button className={`notifications-nav-button ui-small-medium${activeTab === 'read' ? ' notifications-nav-button_active' : ''}`} onClick={() => loadNotifications('read')}>Read</button>
      </div>
      <div className="notifications-dropdown__body">
        {notifications.length ? (
          <ul className="notifications-list">
            {notifications.map((notification, key) => (
              <NotificationItem
                notification={notification}
                key={key}
              />
            ))}
          </ul>
        ) : <NotificationsEmpty />}
      </div>
    </div>
  );
}

export default NotificationsDropdown;