// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainContent_base__LCP3Q {
  overflow: auto;
}
.MainContent_base__LCP3Q.MainContent_full-height__\\+IrNK {
  height: 100%;
}
.MainContent_base__LCP3Q.MainContent_full-height__\\+IrNK .MainContent_inner__Mmxsc {
  height: 100%;
}
.MainContent_base__LCP3Q .MainContent_inner__Mmxsc {
  width: 1184px;
  margin: 0 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/MainContent.module.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAGE;EACE,YAAA;AADJ;AAGI;EACE,YAAA;AADN;AAKE;EACE,aAAA;EACA,cAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AAHJ","sourcesContent":["@import \"../sass//variables.scss\";\n\n.base {\n  overflow: auto;\n\n  &.full-height {\n    height: 100%;\n\n    .inner {\n      height: 100%;\n    }\n  }\n\n  .inner {\n    width: 1184px;\n    margin: 0 auto;\n    overflow: auto;\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n    padding: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `MainContent_base__LCP3Q`,
	"full-height": `MainContent_full-height__+IrNK`,
	"inner": `MainContent_inner__Mmxsc`
};
export default ___CSS_LOADER_EXPORT___;
