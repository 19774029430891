// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switcher {
  display: flex;
  gap: 8px;
  font-size: 14px;
  justify-content: center;
  margin-top: 14px;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/switcher/Switcher.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,QAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;AACD","sourcesContent":[".switcher {\n\tdisplay: flex;\n\tgap: 8px;\n\tfont-size: 14px;\n\tjustify-content: center;\n\tmargin-top: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
