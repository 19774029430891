// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaign-name {
  display: flex;
  gap: 8px;
  align-items: center;
}

.campaigns-table th:nth-child(4), .campaigns-table th:nth-child(5), .campaigns-table th:nth-child(6),
.campaigns-table td:nth-child(4),
.campaigns-table td:nth-child(5),
.campaigns-table td:nth-child(6) {
  text-align: right;
}
.campaigns-table th:nth-child(4) > div > div, .campaigns-table th:nth-child(5) > div > div, .campaigns-table th:nth-child(6) > div > div,
.campaigns-table td:nth-child(4) > div > div,
.campaigns-table td:nth-child(5) > div > div,
.campaigns-table td:nth-child(6) > div > div {
  justify-content: flex-end;
}
.campaigns-table th:nth-child(2) {
  text-align: right;
}
.campaigns-table th:nth-child(2) > div > div {
  justify-content: flex-end;
}
.campaigns-table td:nth-child(2) {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/campaigns/YourCampaigns.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACF;;AAKI;;;;EAGE,iBAAA;AADN;AAGQ;;;;EACE,yBAAA;AAEV;AAKI;EACE,iBAAA;AAHN;AAKQ;EACE,yBAAA;AAHV;AASI;EACE,kBAAA;AAPN","sourcesContent":[".campaign-name {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n\n.campaigns-table {\n  th,\n  td {\n    &:nth-child(4),\n    &:nth-child(5),\n    &:nth-child(6) {\n      text-align: right;\n      > div {\n        > div {\n          justify-content: flex-end;\n        }\n      }\n    }\n    \n  }\n  th {\n    &:nth-child(2) {\n      text-align: right;\n      > div {\n        > div {\n          justify-content: flex-end;\n        }\n      }\n    }\n  }\n  td {\n    &:nth-child(2) {\n      text-align: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
