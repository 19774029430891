// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaigns-list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.campaigns-list .campaign-card {
  width: calc(33.3333333% - 16px);
}`, "",{"version":3,"sources":["webpack://./src/campaigns/List.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AACF;AAAE;EACE,+BAAA;AAEJ","sourcesContent":[".campaigns-list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 24px;\n  .campaign-card {\n    width: calc(33.3333333% - 16px);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
