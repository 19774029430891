// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview-emails {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.overview-emails__count {
  font-size: 21px;
  line-height: 1.33;
  letter-spacing: 0.054px;
  font-weight: 600;
  font-weight: 600;
}
.overview-emails__label {
  font-size: 10px;
  line-height: 1.71;
}

.overview-total-emails-sent {
  height: 312px;
}`, "",{"version":3,"sources":["webpack://./src/campaigns/overviews/Overview.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,kBAAA;AACF;AAAE;EACE,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,eAAA;EACA,iBAAA;AAEJ;;AAEA;EACE,aAAA;AACF","sourcesContent":[".overview-emails {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  text-align: center;\n  &__count {\n    font-size: 21px;\n    line-height: 1.33;\n    letter-spacing: 0.054px;\n    font-weight: 600;\n    font-weight: 600;\n  }\n  &__label {\n    font-size: 10px;\n    line-height: 1.71;\n  }\n}\n\n.overview-total-emails-sent {\n  height: 312px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
