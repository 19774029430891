// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}
.legend__label {
  display: flex;
  gap: 4px;
  align-items: center;
}
.legend__color {
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
.legend__value {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/campaigns/overviews/Legend.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AAEJ;AAAE;EACE,UAAA;EACA,WAAA;EACA,kBAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ","sourcesContent":[".legend {\n  display: flex;\n  gap: 8px;\n  justify-content: space-between;\n  align-items: center;\n  &__label {\n    display: flex;\n    gap: 4px;\n    align-items: center;\n  }\n  &__color {\n    width: 8px;\n    height: 8px;\n    border-radius: 2px;\n  }\n  &__value {\n    white-space: nowrap;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
