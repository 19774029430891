// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_base__VokNW {
  padding: 8px 2px 0;
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-end;
}
.Navigation_base__VokNW button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  font-weight: 500;
  width: 70px;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.06px;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/customization/coupon-editor/Navigation.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,6BAAA;EACA,aAAA;EACA,yBAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADJ","sourcesContent":["@import \"../../../sass/variables.scss\";\n\n.base {\n  padding: 8px 2px 0;\n  border-top: 1px solid $grayGray5;\n  display: flex;\n  justify-content: flex-end;\n\n  button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 8px;\n    height: 32px;\n    font-weight: 500;\n    width: 70px;\n    font-size: 12px;\n    line-height: 1.67;\n    letter-spacing: 0.06px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `Navigation_base__VokNW`
};
export default ___CSS_LOADER_EXPORT___;
