// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-filters {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 63px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/widgets/filters/SelectedFilters.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,uBAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;AACF","sourcesContent":[".selected-filters {\n  display: flex;\n  gap: 8px;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  height: 63px;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
