// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpsellTypeEditor_selected-products__LV9B1 {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 300px;
  overflow: auto;
}

.UpsellTypeEditor_type-divider__xQe4U {
  margin: 0 -9px;
}

.UpsellTypeEditor_products__J8nAl {
  overflow: auto;
  display: flex;
  gap: 8px;
  flex-direction: column;
  max-height: 215px;
}
.UpsellTypeEditor_products__J8nAl label {
  display: flex;
  align-items: center;
  min-height: 48px;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/customization/upsell-type-editor/UpsellTypeEditor.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,QAAA;EACA,iBAAA;EACA,cAAA;AADF;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,cAAA;EACA,aAAA;EACA,QAAA;EACA,sBAAA;EACA,iBAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AADJ","sourcesContent":["@import \"../../../sass/variables.scss\";\n\n.selected-products {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n  max-height: 300px;\n  overflow: auto;\n}\n\n.type-divider {\n  margin: 0 -9px;\n}\n\n.products {\n  overflow: auto;\n  display: flex;\n  gap: 8px;\n  flex-direction: column;\n  max-height: 215px;\n\n  label {\n    display: flex;\n    align-items: center;\n    min-height: 48px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected-products": `UpsellTypeEditor_selected-products__LV9B1`,
	"type-divider": `UpsellTypeEditor_type-divider__xQe4U`,
	"products": `UpsellTypeEditor_products__J8nAl`
};
export default ___CSS_LOADER_EXPORT___;
