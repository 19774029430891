// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentHistory_payment-history__head__\\+stSG {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.PaymentHistory_payment-history__actions__AzPVK {
  display: flex;
  align-items: center;
  gap: 12px;
}
.PaymentHistory_payment-history__z-cdp td {
  min-height: 72px;
}
.PaymentHistory_payment-history__z-cdp td:nth-child(5) {
  text-align: center;
}
.PaymentHistory_payment-history__z-cdp td:nth-child(6) {
  text-align: right;
}
.PaymentHistory_payment-history__z-cdp td svg {
  display: block;
}
.PaymentHistory_payment-history__z-cdp th:nth-child(5) > div > div, .PaymentHistory_payment-history__z-cdp th:nth-child(6) > div > div {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/billing/PaymentHistory.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AACI;EACE,kBAAA;AACN;AACI;EACE,iBAAA;AACN;AACI;EACE,cAAA;AACN;AAMQ;EACE,yBAAA;AAJV","sourcesContent":[".payment-history {\n  &__head {\n    display: flex;\n    justify-content: space-between;\n    gap: 24px;\n  }\n  &__actions {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n  }\n  td {\n    min-height: 72px;\n    &:nth-child(5) {\n      text-align: center;\n    }\n    &:nth-child(6) {\n      text-align: right;\n    }\n    svg {\n      display: block;\n    }\n  }\n  th {\n    &:nth-child(5),\n    &:nth-child(6) {\n      > div {\n        > div {\n          justify-content: flex-end;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment-history__head": `PaymentHistory_payment-history__head__+stSG`,
	"payment-history__actions": `PaymentHistory_payment-history__actions__AzPVK`,
	"payment-history": `PaymentHistory_payment-history__z-cdp`
};
export default ___CSS_LOADER_EXPORT___;
