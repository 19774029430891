// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  max-height: 312px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/performance/Timeline.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;EACA,cAAA;AACF","sourcesContent":[".timeline {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  gap: 16px;\n  max-height: 312px;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
