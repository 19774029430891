// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SurveyWorkspace_base__wgQwf {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
}
.SurveyWorkspace_base__wgQwf .SurveyWorkspace_header__CBRvm {
  display: flex;
  gap: 32px;
  justify-content: space-between;
}
.SurveyWorkspace_base__wgQwf .SurveyWorkspace_placeholder__q7P\\+E {
  min-height: 412px;
}
.SurveyWorkspace_base__wgQwf .SurveyWorkspace_body__zTrb3 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/customization/survey-workspace/SurveyWorkspace.module.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,cAAA;AADF;AAGE;EACE,aAAA;EACA,SAAA;EACA,8BAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHJ","sourcesContent":["@import \"../../../sass/variables.scss\";\n\n.base {\n  border: 1px solid $grayGray5;\n  border-radius: 8px;\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  overflow: auto;\n\n  .header {\n    display: flex;\n    gap: 32px;\n    justify-content: space-between;\n  }\n\n  .placeholder {\n    min-height: 412px;\n  }\n\n  .body {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `SurveyWorkspace_base__wgQwf`,
	"header": `SurveyWorkspace_header__CBRvm`,
	"placeholder": `SurveyWorkspace_placeholder__q7P+E`,
	"body": `SurveyWorkspace_body__zTrb3`
};
export default ___CSS_LOADER_EXPORT___;
