// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-settings__section {
  padding-bottom: 24px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 24px;
}
.store-settings__section:last-child {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/store-settings/StoreSettings.scss"],"names":[],"mappings":"AAGE;EACE,oBAAA;EACA,gCAAA;EACA,mBAAA;AAFJ;AAGI;EACE,gBAAA;AADN","sourcesContent":["@import '../../sass/variables.scss';\n\n.store-settings {\n  &__section {\n    padding-bottom: 24px;\n    border-bottom: 1px solid $grayGray5;\n    margin-bottom: 24px;\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
