// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.performance-card {
  display: flex;
  gap: 9px;
  align-items: center;
}
.performance-card__icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-size: 20px;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.0015em;
  text-align: center;
}
.performance-card__data {
  width: calc(100% - 49px);
}
.performance-card__values {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/performance/Card.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,wBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,wBAAA;AAEJ;AAAE;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AAEJ","sourcesContent":[".performance-card {\n  display: flex;\n  gap: 9px;\n  align-items: center;\n  &__icon {\n    width: 40px;\n    height: 40px;\n    border-radius: 8px;\n    font-size: 20px;\n    line-height: 1.4;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    letter-spacing: 0.0015em;\n    text-align: center;\n  }\n  &__data {\n    width: calc(100% - 49px);\n  }\n  &__values {\n    display: flex;\n    justify-content: space-between;\n    gap: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
