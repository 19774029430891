// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../static/images/select-your-plan/Bg.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../static/images/select-your-plan/Blob.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat right -261px top 305px;
  position: relative;
  min-height: 100dvh;
  overflow: hidden;
}
.onboarding_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.onboarding:before {
  width: 922px;
  height: 948px;
  content: "";
  display: block;
  position: absolute;
  left: -344px;
  top: -97px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center center;
}
.onboarding__inner {
  position: relative;
  z-index: 1;
}
.onboarding-back {
  position: absolute;
  top: 15px;
  right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/onboarding/Onboarding.scss"],"names":[],"mappings":"AAAA;EACE,oFAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AAEJ;AAAE;EACE,YAAA;EACA,aAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,2EAAA;AAEJ;AAAE;EACE,kBAAA;EACA,UAAA;AAEJ;AAAE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AAEJ","sourcesContent":[".onboarding {\n  background: url(../static/images/select-your-plan/Bg.svg) no-repeat right -261px top 305px;\n  position: relative;\n  min-height: 100dvh;\n  overflow: hidden;\n  &_center {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n  &:before {\n    width: 922px;\n    height: 948px;\n    content: '';\n    display: block;\n    position: absolute;\n    left: -344px;\n    top: -97px;\n    background: url(../static/images/select-your-plan/Blob.svg) no-repeat center center;\n  }\n  &__inner {\n    position: relative;\n    z-index: 1;\n  }\n  &-back {\n    position: absolute;\n    top: 15px;\n    right: 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
