import { CircleCheckFill } from "akar-icons";
import "./Notification.scss";

const Notification = props => {
  return (
    <div className="notification">
      <div className="notification__icon">
        <CircleCheckFill
          size="44"
          color="#52c41a"
        />
      </div>
      <div className="notification__message">
        <div className="email-templates-subtitle-2-medium">{props.title}</div>
        <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-8">{props.message}</div>
      </div>
    </div>
  )
}

export default Notification;