// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installation-status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, calc(-50% - 84px));
}
.installation-status__icon {
  display: block;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.installation-status__inner {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/onboarding/InstallationCompleted.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,QAAA;EACA,0CAAA;AACF;AAAE;EACE,cAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAEJ;AAAE;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".installation-status {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 50%;\n  transform: translate(0, calc(-50% - 84px));\n  &__icon {\n    display: block;\n    margin: 0 auto;\n    width: 64px;\n    height: 64px;\n    border-radius: 50%;\n  }\n  &__inner {\n    max-width: 600px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
