// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-text {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}
.copy-text__button {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/copy-text/CopyText.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,8BAAA;AACF;AAAE;EACE,uBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,eAAA;AAEJ","sourcesContent":[".copy-text {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  justify-content: space-between;\n  &__button {\n    background: transparent;\n    border: 0;\n    outline: 0;\n    padding: 0;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
