// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title.raffle-title {
  display: flex;
  align-items: center;
}
.title.raffle-title > span {
  margin-right: 10px;
}
.title.raffle-title > .chips {
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/pages/campaign/SingleCampaign.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;AAAR;AACQ;EACI,kBAAA;AACZ;AAEQ;EACI,gBAAA;AAAZ","sourcesContent":[".title {\n    &.raffle-title {\n        display: flex;\n        align-items: center;\n        & > span {\n            margin-right: 10px;\n        }\n\n        & > .chips {\n            font-weight: 400;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
