// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-preview {
  display: flex;
  gap: 8px;
  align-items: center;
}
.product-preview__image {
  width: 40px;
  height: 40px;
  display: block;
  margin: 0;
  object-fit: contain;
}
.product-preview__name {
  width: calc(100% - 48px);
  text-overflow: ellipsis;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/performance/ProductPreview.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,SAAA;EACA,mBAAA;AAEJ;AAAE;EACE,wBAAA;EACA,uBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".product-preview {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  &__image {\n    width: 40px;\n    height: 40px;\n    display: block;\n    margin: 0;\n    object-fit: contain;\n  }\n  &__name {\n    width: calc(100% - 48px);\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
