// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anom-table-wrapper {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.anom-table {
  width: 100%;
  border-collapse: collapse;
}
.anom-table th {
  padding: 12px 8px;
  border-bottom: 1px solid #aba3b1;
}
.anom-table td {
  padding: 24px 8px;
  border-bottom: 1px solid #e8e8e8;
}
.anom-table tr:last-child td {
  border-bottom: 0;
}
.anom-table__th_w_33 {
  width: 33.3333333%;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/table/Table.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,yBAAA;AADF;;AAIA;EACE,WAAA;EACA,yBAAA;AADF;AAEE;EACE,iBAAA;EACA,gCAAA;AAAJ;AAEE;EACE,iBAAA;EACA,gCAAA;AAAJ;AAIM;EACE,gBAAA;AAFR;AAQM;EACE,kBAAA;AANR","sourcesContent":["@import '../../sass/variables.scss';\n\n.anom-table-wrapper {\n  border-radius: 4px;\n  border: 1px solid $grayGray5;\n}\n\n.anom-table {\n  width: 100%;\n  border-collapse: collapse;\n  th {\n    padding: 12px 8px;\n    border-bottom: 1px solid $secondaryColourSecondary4;\n  }\n  td {\n    padding: 24px 8px;\n    border-bottom: 1px solid $grayGray4;\n  }\n  tr {\n    &:last-child {\n      td {\n        border-bottom: 0;;\n      }\n    }\n  }\n  &__th {\n    &_w {\n      &_33 {\n        width: 33.3333333%;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
