// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}
.section-head__title {
  flex: 1 1;
}
.section-head__filter {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}
.section-head__filter-without_shrink {
  flex-shrink: 0;
}
.section-head_align_center {
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/common/SectionHead.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,8BAAA;EACA,QAAA;AACF;AACE;EACE,SAAA;AACJ;AAEE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAII;EACE,mBAAA;AAFN","sourcesContent":[".section-head {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  gap: 8px;\n  \n  &__title {\n    flex: 1;\n  }\n  \n  &__filter {\n    position: relative;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    \n    &-without_shrink {\n      flex-shrink: 0;\n    }\n  }\n  &_align {\n    &_center {\n      align-items: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
