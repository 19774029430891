// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-r {
  border-right: 1px solid #d9d9d9;
}

.icon-wrapper {
  display: grid;
  gap: 8px;
  grid-template-columns: 20% 1fr;
}
.icon-wrapper .campaign-icon {
  height: 48px;
  width: 48px;
  font-size: 28px;
}

.legends {
  display: flex;
  flex-direction: column;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/tools/Tools.scss"],"names":[],"mappings":"AAEA;EACC,+BAAA;AADD;;AAIA;EACC,aAAA;EACC,QAAA;EACD,8BAAA;AADD;AAGE;EACE,YAAA;EACA,WAAA;EACA,eAAA;AADJ;;AAKA;EACC,aAAA;EACA,sBAAA;EACA,QAAA;AAFD","sourcesContent":["@import '../../sass/variables.scss';\n\n.border-r {\n\tborder-right: 1px solid $grayGray5;\n}\n\n.icon-wrapper {\n\tdisplay: grid;\n  gap: 8px;\n\tgrid-template-columns: 20% 1fr;\n  \n  & .campaign-icon {\n    height: 48px;\n    width: 48px;\n    font-size: 28px;\n  }\n}\n\n.legends {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
