// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stars-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.stars-container_align_left {
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/common/Stars.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AACF;AACI;EACE,2BAAA;AACN","sourcesContent":[".stars-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 4px;\n  &_align {\n    &_left {\n      justify-content: flex-start;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
