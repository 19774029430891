// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-question {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 320px);
}
.onboarding-question__answers {
  overflow: auto;
  padding-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/onboarding/Question.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,4BAAA;AACF;AAAE;EACE,cAAA;EACA,mBAAA;AAEJ","sourcesContent":[".onboarding-question {\n  display: flex;\n  flex-direction: column;\n  height: calc(100dvh - 320px);\n  &__answers {\n    overflow: auto;\n    padding-bottom: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
