// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorPicker_base__\\+\\+Kez [class~=dropdown] {
  left: 0;
}
.ColorPicker_base__\\+\\+Kez .ColorPicker_color-box__xJ8OS {
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  display: block;
  border-radius: 2px;
  bottom: 10px;
  left: 14px;
  z-index: 1;
  position: absolute;
}
.ColorPicker_base__\\+\\+Kez input {
  padding-left: 54px;
  cursor: pointer;
  text-transform: uppercase;
}
.ColorPicker_base__\\+\\+Kez [class~=sketch-picker] {
  width: -moz-fit-content !important;
  width: fit-content !important;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/color-picker/ColorPicker.module.scss"],"names":[],"mappings":"AAGE;EACE,OAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,UAAA;EACA,kBAAA;AAHJ;AAME;EACE,kBAAA;EACA,eAAA;EACA,yBAAA;AAJJ;AAOE;EACE,kCAAA;EAAA,6BAAA;AALJ","sourcesContent":["@import \"../../sass/variables.scss\";\n\n.base {\n  [class~=\"dropdown\"] {\n    left: 0;\n  }\n\n  .color-box {\n    width: 32px;\n    height: 32px;\n    border: 1px solid $grayGray5;\n    display: block;\n    border-radius: 2px;\n    bottom: 10px;\n    left: 14px;\n    z-index: 1;\n    position: absolute;\n  }\n\n  input {\n    padding-left: 54px;\n    cursor: pointer;\n    text-transform: uppercase;\n  }\n\n  [class~=\"sketch-picker\"] {\n    width: fit-content !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `ColorPicker_base__++Kez`,
	"color-box": `ColorPicker_color-box__xJ8OS`
};
export default ___CSS_LOADER_EXPORT___;
