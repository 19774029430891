// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-location-table td {
  min-height: 64px;
}
.user-location-table td:nth-child(3), .user-location-table td:nth-child(4) {
  text-align: right;
}
.user-location-table td:nth-child(3) > div > div, .user-location-table td:nth-child(4) > div > div {
  justify-content: flex-end;
}
.user-location-table th:nth-child(3), .user-location-table th:nth-child(4) {
  text-align: right;
}
.user-location-table th:nth-child(3) > div > div, .user-location-table th:nth-child(4) > div > div {
  justify-content: flex-end;
}
.user-location-table tbody {
  max-height: 257px;
  overflow: auto;
}
.user-location-table table {
  max-height: 297px;
  overflow: auto;
}
.user-location-table thead {
  position: sticky;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/campaigns/overviews/UserLocation.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AACI;EAEE,iBAAA;AAAN;AAEQ;EACE,yBAAA;AAAV;AAMI;EAEE,iBAAA;AALN;AAOQ;EACE,yBAAA;AALV;AAUE;EACE,iBAAA;EACA,cAAA;AARJ;AAUE;EACE,iBAAA;EACA,cAAA;AARJ;AAUE;EACE,gBAAA;EACA,MAAA;AARJ","sourcesContent":[".user-location-table {\n  td {\n    min-height: 64px;\n    &:nth-child(3),\n    &:nth-child(4) {\n      text-align: right;\n      > div {\n        > div {\n          justify-content: flex-end;\n        }\n      }\n    }\n  }\n  th {\n    &:nth-child(3),\n    &:nth-child(4) {\n      text-align: right;\n      > div {\n        > div {\n          justify-content: flex-end;\n        }\n      }\n    }\n  }\n  tbody {\n    max-height: 257px;\n    overflow: auto;\n  }\n  table {\n    max-height: 297px;\n    overflow: auto;\n  }\n  thead {\n    position: sticky;\n    top: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
