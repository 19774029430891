// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepWizard_base__-zMsz {
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 100%;
  padding-bottom: 100px;
  position: relative;
}
.StepWizard_base__-zMsz .StepWizard_stepper__JnZsF {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.StepWizard_header__zqP1t {
  text-align: center;
}

.StepWizard_body__ddb\\+L {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 0 10px;
}

.StepWizard_footer__7tnN8 {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/step-wizard/StepWizard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;AACF;AACE;EACE,SAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,2BAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,YAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;AAAF","sourcesContent":[".base {\n  display: flex;\n  flex-direction: column;\n  gap: 48px;\n  height: 100%;\n  padding-bottom: 100px;\n  position: relative;\n\n  .stepper {\n    margin: 0;\n    position: absolute;\n    bottom: 0;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n}\n\n.header {\n  text-align: center;\n}\n\n.body {\n  height: 100%;\n  overflow: auto;\n  overflow-x: hidden;\n  padding: 0 0 10px;\n}\n\n.footer {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `StepWizard_base__-zMsz`,
	"stepper": `StepWizard_stepper__JnZsF`,
	"header": `StepWizard_header__zqP1t`,
	"body": `StepWizard_body__ddb+L`,
	"footer": `StepWizard_footer__7tnN8`
};
export default ___CSS_LOADER_EXPORT___;
