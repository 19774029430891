// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaign-performance-link {
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-performance-link__name {
  width: calc(100% - 48px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.campaign-performance-table th:nth-child(3), .campaign-performance-table th:nth-child(4), .campaign-performance-table th:nth-child(5), .campaign-performance-table th:nth-child(6),
.campaign-performance-table td:nth-child(3),
.campaign-performance-table td:nth-child(4),
.campaign-performance-table td:nth-child(5),
.campaign-performance-table td:nth-child(6) {
  text-align: right;
}
.campaign-performance-table th:nth-child(3) > div > div, .campaign-performance-table th:nth-child(4) > div > div, .campaign-performance-table th:nth-child(5) > div > div, .campaign-performance-table th:nth-child(6) > div > div,
.campaign-performance-table td:nth-child(3) > div > div,
.campaign-performance-table td:nth-child(4) > div > div,
.campaign-performance-table td:nth-child(5) > div > div,
.campaign-performance-table td:nth-child(6) > div > div {
  justify-content: flex-end;
}
.campaign-performance-table table {
  max-height: 360px;
  overflow: auto;
}
.campaign-performance-table table thead {
  position: sticky;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/performance/CampaignPerformance.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AAAE;EACE,wBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAEJ;;AAKI;;;;;EAIE,iBAAA;AADN;AAGQ;;;;;EACE,yBAAA;AAGV;AAEE;EACE,iBAAA;EACA,cAAA;AAAJ;AACI;EACE,gBAAA;EACA,MAAA;AACN","sourcesContent":[".campaign-performance-link {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  &__name {\n    width: calc(100% - 48px);\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n\n.campaign-performance-table {\n  th,\n  td {\n    &:nth-child(3),\n    &:nth-child(4),\n    &:nth-child(5),\n    &:nth-child(6) {\n      text-align: right;\n      > div {\n        > div {\n          justify-content: flex-end;\n        }\n      }\n    }\n  }\n  table {\n    max-height: 360px;\n    overflow: auto;\n    thead {\n      position: sticky;\n      top: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
