// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-intro {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  height: inherit;
  flex-direction: column;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/onboarding/Intro.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,eAAA;EACA,sBAAA;EACA,WAAA;AACF","sourcesContent":[".onboarding-intro {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 32px;\n  height: inherit;\n  flex-direction: column;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
