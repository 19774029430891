// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coming-soon {
  padding: 0 4px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/common/ComingSoon.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,kBAAA;EACA,yBAAA;EACA,oCAAA;EACA,cAAA;EACA,yBAAA;AADF","sourcesContent":["@import '../../sass/variables.scss';\n\n.coming-soon {\n  padding: 0 4px;\n  border-radius: 4px;\n  border: 1px solid $grayGray1;\n  background: rgba(255, 255, 255, 0.5);\n  display: block;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
