// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonContent_base__OuKTP {
  margin: -16px -9px 0;
  padding: 8px;
  outline: 1px solid #d9d9d9;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/customization/customization-forms/ButtonContent.module.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,YAAA;EACA,0BAAA;EACA,iBAAA;AADF","sourcesContent":["@import \"../../../sass/variables.scss\";\n\n.base {\n  margin: -16px -9px 0;\n  padding: 8px;\n  outline: 1px solid $grayGray5;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `ButtonContent_base__OuKTP`
};
export default ___CSS_LOADER_EXPORT___;
