// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-questionnaire {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.onboarding-questionnaire__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.onboarding-questionnaire__sync {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/onboarding/Questionnaire.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,8BAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;AAAE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEJ","sourcesContent":[".onboarding-questionnaire {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: space-between;\n  position: relative;\n  &__bottom {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  &__sync {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
