// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-button {
  padding: 4px 0;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/buttons/LinkButton.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,qBAAA;AACF","sourcesContent":[".link-button {\n  padding: 4px 0;\n  display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
