// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.package-item__emails {
  border-top: 1px solid #e8e8e8;
}
.package-item__button {
  display: block;
  width: 100%;
}
.package-item__included-label {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
}

.services-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.services-list__item {
  width: calc(25% - 6px);
  display: flex;
  gap: 8px;
  align-items: center;
}
.services-list__item svg {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/billing/PackageItem.scss"],"names":[],"mappings":"AAGE;EACE,6BAAA;AAFJ;AAIE;EACE,cAAA;EACA,WAAA;AAFJ;AAIE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AAFJ;;AAMA;EACE,gBAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;EACA,QAAA;EACA,eAAA;AAHF;AAIE;EACE,sBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AAFJ;AAGI;EACE,cAAA;AADN","sourcesContent":["@import '../sass/variables.scss';\n\n.package-item {\n  &__emails {\n    border-top: 1px solid $grayGray4;\n  }\n  &__button {\n    display: block;\n    width: 100%;\n  }\n  &__included-label {\n    font-size: 14px;\n    line-height: 1.5;\n    font-weight: 600;\n  }\n}\n\n.services-list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n  &__item {\n    width: calc(25%  - 6px);\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    svg {\n      display: block;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
