// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-group_base__Fh7aj {
  display: flex;
}
.button-group_base__Fh7aj button {
  border-radius: 0;
}
.button-group_base__Fh7aj button:nth-child(odd) {
  border-right: 1px solid white;
  margin-right: -0.75px;
}
.button-group_base__Fh7aj button:nth-child(even) {
  border-left: 1px solid white;
  margin-left: -0.75px;
}
.button-group_base__Fh7aj button:first-child {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.button-group_base__Fh7aj button:last-child {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}`, "",{"version":3,"sources":["webpack://./src/ui-elements/button-group/button-group.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,gBAAA;AACJ;AACI;EACE,6BAAA;EACA,qBAAA;AACN;AAEI;EACE,4BAAA;EACA,oBAAA;AAAN;AAGI;EACE,4CAAA;EACA,+CAAA;AADN;AAGI;EACE,6CAAA;EACA,gDAAA;AADN","sourcesContent":[".base {\n  display: flex;\n\n  button {\n    border-radius: 0;\n\n    &:nth-child(odd) {\n      border-right: 1px solid white;\n      margin-right: -0.75px;\n    }\n\n    &:nth-child(even) {\n      border-left: 1px solid white;\n      margin-left: -0.75px;\n    }\n\n    &:first-child {\n      border-top-left-radius: var(--border-radius);\n      border-bottom-left-radius: var(--border-radius);\n    }\n    &:last-child {\n      border-top-right-radius: var(--border-radius);\n      border-bottom-right-radius: var(--border-radius);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `button-group_base__Fh7aj`
};
export default ___CSS_LOADER_EXPORT___;
