// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-email__iframe {
  width: 100%;
  border: 0;
  height: 100dvh;
}`, "",{"version":3,"sources":["webpack://./src/view-email/ViewEmail.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,SAAA;EACA,cAAA;AAAJ","sourcesContent":[".view-email {\n  &__iframe {\n    width: 100%;\n    border: 0;\n    height: 100dvh;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
