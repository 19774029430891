// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widget-organization-select {
  padding-left: 10px;
  padding-right: 10px;
}
.widget-organization-select .form-select {
  padding-top: 8px;
  padding-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/campaign/WidgetOrganizationStep.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;AAAE;EACE,gBAAA;EACA,mBAAA;AAEJ","sourcesContent":[".widget-organization-select {\n  padding-left: 10px;\n  padding-right: 10px;\n  .form-select {\n    padding-top: 8px;\n    padding-bottom: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
