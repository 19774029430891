// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader {
  position: fixed;
  width: 64px;
  height: 64px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 2;
}
.preloader__icon {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.preloader_absolute {
  position: absolute;
}
.preloader_overflow {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  transform: none;
}
.preloader_overflow-opaque {
  background: rgb(255, 255, 255);
}
.preloader_overflow .preloader__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 64px;
  height: 64px;
  margin-top: -32px;
  margin-left: -32px;
}
.preloader_br_24 {
  border-radius: 24px;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/preloader/Preloader.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,gCAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;AADF;AAEE;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,SAAA;EACA,8DAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ;AAEE;EACE,oCAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAAJ;AACI;EACE,8BAAA;AACN;AAEM;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAAR;AAIE;EACE,mBAAA;AAFJ;;AAMA;EACE;IACE,oBAAA;EAHF;EAKA;IACE,yBAAA;EAHF;AACF","sourcesContent":["@import '../sass/variables.scss';\n\n.preloader {\n  position: fixed;\n  width: 64px;\n  height: 64px;\n  transform: translate(-50%, -50%);\n  top: 50%;\n  left: 50%;\n  z-index: 2;\n  &__icon {\n    width: 100%;\n    height: 100%;\n    display: block;\n    margin: 0;\n    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  }\n  &_absolute {\n    position: absolute;\n  }\n  &_overflow {\n    background: rgb(255, 255, 255, 0.5);\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    width: auto;\n    height: auto;\n    transform: none;\n    &-opaque {\n      background: rgba(255, 255, 255);\n    }\n    .preloader {\n      &__icon {\n        position: absolute;\n        left: 50%;\n        top: 50%;\n        width: 64px;\n        height: 64px;\n        margin-top: -32px;\n        margin-left: -32px;\n      }\n    }\n  }\n  &_br_24 {\n    border-radius: 24px;\n  }\n}\n\n@keyframes lds-ring {\n  0% {\n    transform: rotate(0);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
