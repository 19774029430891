import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import UiSmallRegular from "../ui-elements/text/UiSmallRegular";
import ntWorkingWomanOnLaptop1 from "../static/images/dashboard/nt-working-woman-on-laptop 1.svg";
import "./NotificationsEmpty.scss";

const NotificationsEmpty = () => {
  return (
    <div className="notifications-empty text-center">
      <UiCaptionMedium text="Nothing here to read!" />
      <UiSmallRegular
        margin="8"
        text="You have read all your notifications"
      />
      <img
        src={ntWorkingWomanOnLaptop1}
        alt="Nothing here to read!"
        className="mt-24 responsive-img"
      />
    </div>
  )
}

export default NotificationsEmpty;